h1, h2, h3, h4, h5, h6, input, textarea {
    padding: 0;
    margin: 0;
    transition: 0.5s;
    color: #02196C;
}

body {
    margin: 0;
    font-family: 'Inter',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #02196C;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea {
    resize: none
}

textarea, input[type="text"], input[type="password"] {
    height: 100%;
    width: 100%;
    border-style: none;
    font-size: 16px;
    background-color: transparent;
}

textarea:focus, input:focus {
    outline: none !important;
}

textarea::placeholder, input::placeholder {
    color: rgba(50, 59, 75, 0.75);
}

.buttonHover {
    transition: 0.3s;
}

.buttonHover:hover {
    transition: 0.3s;
    background-color: rgba(0, 94, 255, 0.05);
}

.flex {
    flex: 1
}

.container {
    display: flex;
    flex-direction: column;
}

.containerRow {
    display: flex;
    flex-direction: row;
}

.containerRowCenter {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.containerRowSpaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.containerCenter {
    flex-direction: column;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.separatorHorizontal {
    width: 100%;
    height: 1px;
    background-color: rgba(55, 125, 255, 0.05);
}

.rbc-allday-cell {
    display: none;
}

.hideScrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hideScrollbars::-webkit-scrollbar { /* WebKit */
    width: 0 !important;
    height: 0;
}

.marker {
    transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
    transform-origin: 50% 50%;
    border-radius: 50% 50% 50% 0;
    width: 40px;
    height: 40px;
    background: #ed1f34;
    margin: -5% 0 0 0;
}
